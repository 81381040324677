jQuery(document).ready(function (){

    jQuery('.bxslider').bxSlider({
		pager: false,
	    auto: (jQuery('.bxslider').children().length < 2) ? false : true,
        touchEnabled:(jQuery('.bxslider').children().length < 2) ? false : true,
		adaptiveHeight:true
    });

    eqHeight();

    jQuery('.navbar-toggle').click(function(){
        jQuery(this).toggleClass('open');
        jQuery('.site').toggleClass('open-menu');
    });

    jQuery('.overlay').click(function(){
        jQuery('.navbar-toggle').removeClass('open');
        jQuery('.site').removeClass('open-menu');
    });

    jQuery('.smoth-scroll').click(function(){
      jQuery('html, body').animate({
        scrollTop: jQuery( jQuery.attr(this, 'href') ).offset().top
      }, 500);
      return false;
    });

     jQuery(".touch .navbar-collapse .navbar-nav li > a").on("click touchstart",function(event){

	   if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
	   {
		event.preventDefault();
		event.stopPropagation();
		jQuery(this).parent().addClass("open").siblings().removeClass("open");
	   }
	  });
});
jQuery(window).load(function (){
    eqHeight();
});
jQuery(window).resize(function (){
    eqHeight();
});

function eqHeight() {
    equalheight('.news-post');
}

function equalheight(container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
                currentRowStart = 0,
                rowDivs = [],
                jQueryel,
                topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            topPostion = jQueryel.offset().top;
            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].innerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.innerHeight();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
}
